import { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from "firebase/app";

 // Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA4EHzc4RRSB7iHNu_zdsiYNue2fgTeVGE",
  authDomain: "maxmarketpro.firebaseapp.com",
  projectId: "maxmarketpro",
  storageBucket: "maxmarketpro.appspot.com",
  messagingSenderId: "269237879545",
  appId: "1:269237879545:web:e8c5f06c194f5306ec1828",
  measurementId: "G-MWVM00HMS2"
};
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSignIn = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // Do something with the user information
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setError(errorMessage);
      });
  };

  return (
    <div>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleSignIn}>Sign In</button>
      {error && <p>{error}</p>}
    </div>
  );
};

export default SignIn;