import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../scss/styles.scss';
function SettingsScreen() {
  const navigate = useNavigate();
  const handleLogout = () => {
    // Perform logout logic here
    navigate('/'); // Redirect to the login screen after logout
  };
  return (
    <>
      <div className="settings-screen">
        <button onClick={handleLogout}>Logout</button>
      </div>
    </>
  );
}
export default SettingsScreen;