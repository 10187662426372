import { createContext, useState } from "react";

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

export const AuthContext = createContext();

const fetchCustomerData = async (email, consumerKey, consumerSecret, url) => {
  const response = await fetch(
    `${url}${email}&consumer_key=${consumerKey}&consumer_secret=${consumerSecret}`,
    {
      method: "GET",
      headers: {
        Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Failed to retrieve customer ID");
  }

  return response.json();
};

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [email, setEmail] = useState("");
  const [customerId, setCustomerId] = useState(null);
  const [hasSubscription, setHasSubscription] = useState("no");
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [triggerApiCall, setTriggerApiCall] = useState(false);


  const login = async (email, password) => {
    setIsLoading(true);
    setErrorMessage(null);

    try {
      // Bypass logic for admin
      if (process.env.REACT_APP_ADMIN_EMAILS.split(',').includes(email)) {
        setIsLoggedIn(true);
        setIsAdmin(true);
      } else {
        // Normal login logic for non-admin users
        const url = process.env.REACT_APP_WCCK_API_URL;
        const consumerKey = process.env.REACT_APP_WCCK_API_KEY;
        const consumerSecret = process.env.REACT_APP_WCCS_API_KEY;

        const customerData = await fetchCustomerData(email, consumerKey, consumerSecret, url);
        const customerId = customerData[0].id;

        const hasActiveSubscription = customerData.some(
          (subscription) =>
            subscription.status === "completed" &&
            subscription.line_items.some(
              (item) => process.env.REACT_APP_SUBSCRIPTION_NAMES.split(',').includes(item.sku)
            )
        );

        setCustomerId(customerId);
        setHasSubscription(hasActiveSubscription);
        console.log('hasSubscription: ' + hasSubscription);
        setIsLoggedIn(true);
        setIsAdmin(false);
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        errorMessage,
        email,
        setEmail,
        customerId,
        hasSubscription,
        isLoggedIn,
        isAdmin,
        setIsAdmin,
        setTriggerApiCall,
        login
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};