import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from '../core/newauth';
import Menu from "../components/MainMenu/Menu";
import "../scss/styles.scss";

function AllCoursesScreen() {
  const { state } = useLocation();
  const { auth } = state || {};
  const { isLoggedIn, isAdmin, hasSubscription } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("isLoggedIn:", isLoggedIn);
    if (auth) {
      console.log("Auth:", auth);
      return;
    }
    if (!isLoggedIn) {
      navigate("/logout");
    }
  }, [isLoggedIn, navigate, auth]);

  return (
    <>
      <Menu />
      <div
        className="all-courses-screen"
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          padding: "5% 20%",
        }}
      >
        <iframe
          src="https://player.vimeo.com/video/788703378?h=5f17c35cb3"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
        <br /> <br />
        <iframe
          src="https://player.vimeo.com/video/788703428?h=159856f20e"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
        <br /> <br />
        <iframe
          src="https://player.vimeo.com/video/788703515?h=25196a3d9a"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
        <br /> <br />
        <iframe
          src="https://player.vimeo.com/video/788703557?h=689df1fcb2"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
        <br /> <br />
        <iframe
          src="https://player.vimeo.com/video/788703604?h=9a785f79a5"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
        <br /> <br />
        <iframe
          src="https://player.vimeo.com/video/788703670?h=d3d1918856"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
        <br /> <br />
        <iframe
          src="https://player.vimeo.com/video/788703748?h=2b746139ee"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
        <br /> <br />
        <iframe
          src="https://player.vimeo.com/video/788703827?h=f4501e808e"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
        <br /> <br />
        <iframe
          src="https://player.vimeo.com/video/788703977?h=a871e63eff"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
        <br /> <br />
        <iframe
          src="https://player.vimeo.com/video/714975226?h=99f1789460"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
        <br /> <br />
        <iframe
          src="https://player.vimeo.com/video/714975787?h=e968a5cb07"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
        <br /> <br />
        <iframe
          src="https://player.vimeo.com/video/716133328?h=dc89237e43"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
        <br /> <br />
        <iframe
          src="https://player.vimeo.com/video/716078449?h=68f9f6ca7e"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
        <br /> <br />
        <iframe
          src="https://player.vimeo.com/video/716078815?h=ef6bcf22ff"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
        <br /> <br />
        <iframe
          src="https://player.vimeo.com/video/714976024?h=e36eecea68"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
        <br /> <br />
        <iframe
          src="https://player.vimeo.com/video/716082022?h=c28c3fa4a1"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
        <br /> <br />
        <iframe
          src="https://player.vimeo.com/video/714987890?h=8b8980b281"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
        <br /> <br />
        <iframe
          src="https://player.vimeo.com/video/714983582?h=a891bbfbb5"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
        <br /> <br />
        <iframe
          src="https://player.vimeo.com/video/716133159?h=bada34873f"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
        <br /> <br />
      </div>
    </>
  );
}

export default AllCoursesScreen;
