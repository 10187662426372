import React from "react";
import Menu from "../components/MainMenu/Menu";
import "../scss/styles.scss";

function EcomAllyScreen() {
  return (
    <>
      <Menu />
      <div className="ecom-ally-screen">
        <iframe
          src="https://ecommob.ai"
          width="100%"
          height="80%"
          title="FLIP"
        />
      </div>
    </>
  );
}

export default EcomAllyScreen;
