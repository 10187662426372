import React, { useEffect, useState } from 'react';

function IdeasEngine({ url, onRequestComplete }) {
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
        });

        if (response.ok) {
          let data = await response.json();
          data = data.slice(0, 10); // Limit to 10 items
          
          if (onRequestComplete) {
            onRequestComplete(data, null);
          }
          //fetchData();
        } else {
          throw new Error('Request failed with status: ' + response.status);
        }
      } catch (error) {
        setError(error);
        if (onRequestComplete) {
          onRequestComplete(null, error);
        }
        console.error(error);
      }
    };

    // Call fetchData immediately
    //

    // Then call fetchData every 30 seconds
    const intervalId = setInterval(fetchData, 5000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [url, onRequestComplete]);

  // No rendering of jsonData here
  return (
    <div>
      {error && <p>Error: {error.message}</p>}
    </div>
  );
}

export default IdeasEngine;