import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from '../core/newauth';
import { getFirestore, arrayUnion, doc, updateDoc, getDoc, onSnapshot }
  from 'firebase/firestore'; import { app, auth }
  from '../core/fb'; import Menu from '../components/MainMenu/Menu';

const AdminScreen = () => {
  const [message, setMessage] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [messages, setMessages] = useState([]);
  const [adminName, setAdminName] = useState('');
  const navigate = useNavigate();
  const { state } = useLocation();
  //const { auth, isAdmin } = state || {};
  const { isLoggedIn, email, isAdmin } = useContext(AuthContext);

  useEffect(() => {
    console.log("isLoggedIn:", isLoggedIn);
    if (auth) {
      console.log("Auth:", auth);
      return;
    }
if (!isLoggedIn || isAdmin) {
      navigate("/logout");
    }
  }, [isLoggedIn, navigate, isAdmin]);
  
  useEffect(() => {
    const db = getFirestore(app);
    const docRef = doc(db, process.env.REACT_APP_CHATROOM);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        const messages = data.messages || [];
        setMessages(messages);
      } else {
        console.log("No such document!");
      }
    });
    //const unsubscribe = onSnapshot(docRef, (doc) => {
    //  const data = doc.data();
    //  const messages = data.messages || [];
    //  setMessages(messages);
    //});
    return unsubscribe;
  }, []);

  useEffect(() => {
    const adminEmails = process.env.REACT_APP_ADMIN_EMAILS.split(',');
    if (adminEmails.includes(email)) {
      console.log('Admin email: ' + email);
      if (email === adminEmails[0]) {
        setAdminName('CodeFather');
      } else if (email === adminEmails[1]) {
        setAdminName('ArkAItect');
      } else if (email === adminEmails[2]) {
        setAdminName('MobFather');
      } else if (email === adminEmails[3]) {
        setAdminName('Capo1');
      } else if (email === adminEmails[4]) {
        setAdminName('Capo2');
      } else if (email === adminEmails[5]) {
        setAdminName('Capo3');
      }
    } 
  }, [email, adminName]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    //if (!email || email !== process.env.REACT_APP_ADMIN_EMAILS.split(',')) {
    //console.log('You must be logged in with the admin email.');
    // return;
    //}

    const db = getFirestore(app);
    const docRef = doc(db, process.env.REACT_APP_CHATROOM);
    const id = Math.floor(Math.random() * 1000000) + 1;
    const time = new Date().toLocaleString();
    try {
      await updateDoc(docRef, {
        messages: arrayUnion({
          id: id.toString(),
          time: time,
          message: message,
          sender: adminName,
          imageURL: imageUrl,
        }),
      });
      setSubmissionStatus('Success! Your message has been submitted.');
      setMessage('');
      setImageUrl('');
    } catch (error) {
      setSubmissionStatus('Error! Failed to submit message.');
    }
  };

  

  return (
    <>
      <Menu />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 100px)' }}>
        <div style={{ borderRadius: '10px', maxWidth: '500px', width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}><br />
          <div style={{ height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', fontWeight: 'bold', fontSize: '30px' }}>Submit Ideas</div>
          <br />
          <div style={{ flex: 1, overflowY: 'scroll', padding: '10px' }}>
            {messages.map((message) => (
              <div key={message.id} style={{ marginBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', overflow: "hidden" }}>
                <div style={{ overflow: "hidden", background: '#007aff', color: '#fff', borderRadius: '10px', padding: '10px   30px   10px   30px', alignSelf: 'flex-end', wordWrap: 'break-word', maxWidth: '80%' }}>
                  {message.message || 'No message'}<br />
                  {message.imageURL !== '' ? <img src={message.imageURL} alt={message.message || 'No image description available'} style={{ maxWidth: '100%', marginTop: '5px' }} /> : ''}
                </div>
                
                <div style={{ overflow: "hidden", fontWeight: 'bold', marginBottom: '5px', marginTop: '5px', color: '#ccc', fontSize: '9pt' }}>
                  {message.time}
                </div>
              </div>
            ))}
          </div>
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', padding: '10px', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
            <input type="text" value={message} onChange={(e) => setMessage(e.target.value)} style={{ width: '100%', background: '#007aff', color: '#fff', borderRadius: '20px', padding: '10px', border: 'none', outline: 'none', height: 'auto', minHeight: '50px' }} placeholder="Type a message" rows={1} />
            <input type="text" value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} style={{ width: '100%', background: '#007aff', color: '#fff', borderRadius: '20px', padding: '10px', border: 'none', outline: 'none' }} placeholder="Image URL" />
            <button type="submit" style={{ textAlign: 'center', flexDirection: 'row', width: '100%', background: '#007aff', color: 'white', fontWeight: 'bold', marginBottom: '10px', borderRadius: '20px', padding: '15px', border: 'none', outline: 'none' }}>Send</button>

          </form>
          {submissionStatus && <div style={{ color: submissionStatus.includes('Success') ? 'green' : 'red', textAlign: 'center', marginTop: '10px' }}>{submissionStatus}</div>}
        </div>
      </div>
    </>
  );
};

export default AdminScreen;