import React, { useState, useContext, useEffect, useCallback, useRef, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { virtualize, bindKeyboard } from "react-swipeable-views-utils";
import Menu from "../components/MainMenu/Menu";
import { AuthContext } from "../core/newauth";
import "../scss/styles.scss";
import flyImage from "../assets/images/fly.png";
import fitImage from "../assets/images/fit.png";

const VirtualizedSwipeableViews = bindKeyboard(virtualize(SwipeableViews));

const slidesData = [
  {
    title: "F.I.T.",
    description:
      'Leverage powerful tools and systems to help you get "FIT" for a better financial future.',
    buttonText: "Get FIT",
    buttonLink: "/fit",
    imageURL: fitImage,
  },
  {
    title: "F.L.Y.",
    subtitle: '',
    description: 'Learn to "FLY" with MOB exclusive education.',
    buttonText: "Let's FLY!",
    buttonLink: "/all-courses",
    imageURL: flyImage,
  }
];
const HomeScreen = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { auth } = state || {};
  const { isLoggedIn, hasSubscription, isAdmin, setIAdmin } = useContext(AuthContext);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    console.log("isLoggedIn:", isLoggedIn);
    if (auth) {
      console.log("Auth:", auth);
      return;
    }
    if (!isLoggedIn) {
      navigate("/logout");
    }
  }, [isLoggedIn, navigate, auth]);

  const handleSwipeUpRef = useRef(null);

  const handleSwipeUp = useCallback(() => {
    if (activeIndex < slidesData.length - 1) {
      setActiveIndex(activeIndex + 1);
    } else {
      setActiveIndex(0);
    }
  }, [activeIndex, setActiveIndex]);

  handleSwipeUpRef.current = handleSwipeUp;

  const slides = useMemo(() => {
    return slidesData.map((slide, index) => ({
      ...slide,
      onSwipeUp: () => handleSwipeUpRef.current(),
    }));
  }, []);

  const ContentCard = React.memo(({
    title,
    subtitle,
    description,
    buttonText,
    buttonLink,
    externalLink,
    imageURL,
  }) => {
    const doClick = () => {
      if (isAdmin) {
        navigate(buttonLink, { replace: true, state: { auth: true, isAdmin: true } });
      } else {
        navigate(buttonLink, { replace: true, state: { auth: true } });
      }
    };

    useEffect(() => {
      const handleKeyPress = (e) => {
        if (e.key === "ArrowUp") {
          handleSwipeUp();
        }
      };
      document.addEventListener("keydown", handleKeyPress);
      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    }, [])

    const renderButton = () => {
      if (externalLink) {
        return (
          <a href={buttonLink}>
            {buttonText}
          </a>
        );
      } else {
        return <button onClick={doClick}>{buttonText}</button>;
      }
    };
    return (
      <div className="content-card">
        <h2 className="fu-title">Swipe Left or Right...</h2>
        <h3>{subtitle}</h3>
        {imageURL && (
          <img src={imageURL} width="700px" height="60%" alt="pic" />
        )}
        <p>{description}</p>
        {renderButton()}
      </div>
    );
  });

  return (
    <>
      <Menu />
      <div className="home-screen">
        <VirtualizedSwipeableViews
          slideCount={slides.length}
          slideRenderer={({ index }) => (
            <div className="slide" key={index}>
              <ContentCard {...slides[index]} />
            </div>
          )}
          index={activeIndex}
          onChangeIndex={(index) => setActiveIndex(index)}
          containerStyle={{ height: "80%" }}
          slideStyle={{ height: "80%" }}
        />
      </div>
    </>
  );
};

export default HomeScreen;