import React, { useEffect, useState, useContext, } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import app from "../core/fb";
import Menu from "../components/MainMenu/Menu";
import { getFirestore, doc, getDoc, onSnapshot } from "firebase/firestore";
import "../scss/styles.scss";
import { AuthContext } from '../core/newauth';

function ChatScreen() {
  const [chatData, setChatData] = useState(null);
  const { isLoggedIn } = useContext(AuthContext);
  const { state } = useLocation();
  const { auth } = state || {};
  const navigate = useNavigate();
  useEffect(() => {
    console.log("isLoggedIn:", isLoggedIn);
    if (auth) {
      console.log("Auth:", auth);
      return;
    }
    if (!auth) {
      console.log("Auth:", auth);
      navigate("/logout");
    }
  }, [isLoggedIn, navigate, auth]);

  useEffect(() => {
    let isMounted = true;

    const fetchMessages = async () => {
      const db = getFirestore(app);
      const docRef = doc(db, process.env.REACT_APP_CHATROOM);

      const unsubscribe = onSnapshot(docRef, (doc) => {
        const data = doc.data();
        const messages = data.messages || [];
        setChatData(messages);
      });
      return unsubscribe;
    };

    fetchMessages();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Menu />
      <h1 className={`ideasTitle`}>Think Tank 🧠</h1>
      <div className="chat-messages">
        {chatData && chatData.length > 0 ? (
          chatData.map((message, index) => (
            <div className={`chat-message-wrapper`}>
              <div
                className={`chat-message ${message.sender === "user1" ? "sent" : "received"
                  }`}
                key={index}
              >
                <p className={`chat-sender-text`}>{message.sender}</p>
                <p className={`chat-message-text`}>{message.message}</p>
                <p className={`chat-time-text`}>Date: {message.time}</p>
              </div>
            </div>
          ))
        ) : (
          <div>No messages available.</div>
        )}
      </div>
    </>
  );
}

export default ChatScreen;
