import React, { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from '../core/newauth';

import Menu from "../components/MainMenu/Menu";
import IdeasEngine from '../components/IdeasEngine/IdeasEngine'; // Assuming IdeasEngine is in this path

import "../scss/styles.scss";

const Strategy = {
  None: "none",
  trendeater: "Trend Eater",
  paperchaser: "Paper Chaser",
  skydweller: "Sky Dweller",
  limitbreaker: "Limit Breaker",
  dreamscalper: "Dream Scalper",
};

const strategyConfigurations = {
  [Strategy.trendeater]: {
    studies:
      "TrueStrengthIndex%40tv-basicstudies%1FWilliamsAlligator%40tv-basicstudies",
    timeframeCheckboxes: [1, 2, 3, 4],
  },
  [Strategy.paperchaser]: {
    studies:
      "MF%40tv-basicstudies%1FPSAR%40tv-basicstudies%1FSupertrend%40tv-basicstudies",
    timeframeCheckboxes: [1, 2, 3, 4],
  },
  [Strategy.skydweller]: {
    studies:
      "DoubleEMA%40tv-basicstudies%1FIchimokuCloud%40tv-basicstudies%1FTripleEMA%40tv-basicstudies",
    timeframeCheckboxes: [1, 2, 3, 4],
  },
  [Strategy.limitbreaker]: {
    studies: "KLTNR%40tv-basicstudies%1FMACD%40tv-basicstudies",
    timeframeCheckboxes: [1, 2, 3, 4],
  },
  [Strategy.dreamscalper]: {
    studies:
      "BB%40tv-basicstudies%1FMAExp%40tv-basicstudies%1FPSAR%40tv-basicstudies%1FRSI%40tv-basicstudies%1FStochastic%40tv-basicstudies%1FVolume%40tv-basicstudies",
    timeframeCheckboxes: [1, 2, 3, 4],
  },
};
function FitScreen() {
  const navigate = useNavigate();
  //const iframeRef = useRef(null); // useRef to manage the iframe
  const { isLoggedIn } = useContext(AuthContext);
  const { state } = useLocation();
  const { auth } = state || {};
  useEffect(() => {
    console.log("isLoggedIn:", isLoggedIn);
    if (auth) {
      console.log("Auth:", auth);
      return;
    }
    if (!isLoggedIn) {
      navigate("/logout");
    }
  }, [isLoggedIn, navigate, auth]);

  const [selectedStrategy, setSelectedStrategy] = useState(Strategy.None);
  const [jsonData, setJsonData] = useState(null);
  const [symbol, setSymbol] = useState(localStorage.getItem("LASTSYMBOL") || "EURUSD");
  const [timeframe, setTimeframe] = useState(localStorage.getItem("LASTTF") || "15");

  const changeChart = useCallback((strategy, newSymbol = symbol, newTimeframe = timeframe) => {
    //const cryptocheck = localStorage.getItem("CRYPTO");
    //const indicescheck = localStorage.getItem("INDICES");
    //const exchange = localStorage.getItem("EXCHANGE");
    //const darkmode = localStorage.getItem("DARKMODE");
    //const cconfig = localStorage.getItem("CCONFIG");
    //const style = localStorage.getItem("BARS") || "1"; // Default value is set to "1" if not found
    const symbol = encodeURIComponent(
      localStorage.getItem("LASTSYMBOL") || "EURUSD"
    );
    const studies = strategyConfigurations[strategy]?.studies || [];

    const url = new URL("https://s.tradingview.com/widgetembed/");
    url.searchParams.set("frameElementId", "tradingview_dea54");
    //url.searchParams.set("symbol", `OANDA:${encodeURIComponent(newSymbol)}`); // Using newSymbol
    url.searchParams.set("symbol", `${newSymbol}`); // Using newSymbol
    url.searchParams.set("interval", newTimeframe);
    //url.searchParams.set("studies", studies);
    url.searchParams.set("toolbarbg", "#131722");
    //url.searchParams.set("hideideas", "1");
    url.searchParams.set("theme", "dark");
    url.searchParams.set("style", "1");
    url.searchParams.set("padding", "0");
    url.searchParams.set("details", "true");
    url.searchParams.set("calendar", "true");
    url.searchParams.set("studies_overrides", "{}");
    url.searchParams.set("overrides", "{}");
    url.searchParams.set("enabled_features", "[]");
    url.searchParams.set("disabled_features", '["symbol_info"]');
    url.searchParams.set("hide_side_toolbar", 'false');
    url.searchParams.set("locale", "en");
    url.searchParams.set("utm_medium", "widget");
    url.searchParams.set("utm_campaign", "chart");
    url.searchParams.set("utm_term", `OANDA:${symbol}`);

    const iframe = document.createElement("iframe");
    iframe.id = "tradingview_dea54";
    iframe.src = url + "&studies=" + studies;
    iframe.style.cssText =
      "width: 100%; height: 95%; margin: 0 !important; padding: 0 !important;";
    iframe.frameBorder = "0";
    iframe.allowtransparency = "true";
    iframe.scrolling = "no";
    iframe.allowfullscreen = "";

    const chartContainer = document.getElementById("chart");
    if (chartContainer) {
      chartContainer.innerHTML = "";
      chartContainer.appendChild(iframe);
    }
  }, [symbol, timeframe]);

  const tsclick = (a, m) => {
    let newSym = a.replace("ecn", "");
    let newTf = m.replace("M", "").replace("H1", "60").replace("H4", "240");

    // Symbol mappings
    const symbolMappings = {
      IOTAUSD: 'IOTUSD',
      DASHUSD: 'DSHUSD',
      XAGUSD: 'OANDA:',
      XAUUSD: 'OANDA:',
      XAUEUR: 'OANDA:',
      XPTUSD: 'OANDA:',
      // ... [add all other mappings here]
    };
    const exchangeMappings = {
      AAPL: 'NASDAQ:',
      EBAY: 'NASDAQ:',
      // ... [add all other exchange mappings here]

    };

    newSym = symbolMappings[newSym] || newSym;
    const exchange = exchangeMappings[newSym] || 'OANDA:';

    const finalSymbol = `${exchange}${newSym}`;
    setSymbol(finalSymbol);
    setTimeframe(newTf);
    changeChart(selectedStrategy, finalSymbol, newTf);
    // ... [Other code logic]
  };
  useEffect(() => {
    changeChart(selectedStrategy);
  }, [selectedStrategy, symbol, timeframe]);


  const handleStrategyChange = (event) => {
    const selectedStrategy = event.target.value;
    setSelectedStrategy(selectedStrategy);
    //changeChart(selectedStrategy);
  };

  // Directly use the data provided by IdeasEngine
  const handleJsonData = (data, error) => {
    if (data) {
      setJsonData(data); // Directly use the data from IdeasEngine
    } else {
      console.error(error);
    }
  };

  return (
    <>
      <Menu />
      <IdeasEngine
        url={process.env.REACT_APP_IDEAS_URL} // Replace with your actual JSON source URL
        onRequestComplete={handleJsonData}
      />
      <div className="fit-screen-wrapper">
        <div className="fit-ideas" style={{ width: "22vw", height: "70%", overflowY: "scroll", position: "fixed", top: "17%" }}>
          {/* Render JSON data here */}
          {jsonData && jsonData.map((item, index) => (
            <div key={index} onClick={() => tsclick(item.symbol, item.timeframe)} style={{ height: '50px', width: "80%", display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid black', padding: '10px' }}>

              {item.signal === 'Buy' ? (
                <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 12L12 4L20 12H4Z" fill="green" />
                </svg>
              ) : (
                <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 12L12 20L20 12H4Z" fill="red" />
                </svg>
              )}
              <br />
              <span style={{ fontSize: "14pt", marginTop: "-5px" }}>{item.symbol}</span>&nbsp;&nbsp;
              <span style={{ fontSize: "14pt", marginTop: "-5px" }}>{item.timeframe}</span>&nbsp;&nbsp;<br />
              <span style={{ fontSize: "10pt", marginTop: "-5px" }} >{item.time}</span>

            </div>
          ))}
        </div>

        <div className="fit-screen" style={{ marginLeft: "0px" }}>
          <ul className="navbar-nav text-navbar" style={{ position: "fixed", left: "-20px", top: "70px" }}>
            <li className="mr-3">
              <form
                className="m-0"
                name="strategies_config"
                id="strategies_config"
                onSubmit={(e) => e.preventDefault()}
              >
                <select
                  className="form-control"
                  name="strategies_select"
                  id="strategies_select"
                  value={selectedStrategy}
                  onChange={handleStrategyChange}
                >
                  <option value={Strategy.None}>STRATEGIES</option>

                  <option value={Strategy.trendeater}>Trend Eater</option>
                  <option value={Strategy.paperchaser}>Paper Chaser</option>
                  <option value={Strategy.skydweller}>Sky Dweller</option>
                  <option value={Strategy.limitbreaker}>Limit Breaker</option>
                  <option value={Strategy.dreamscalper}>Dream Scalper</option>
                  {/* Add more options */}
                </select>
              </form>
            </li>
          </ul>
          <div className="fit-items">
            <div className="trading-view-panel" id="chart">
              <div
                id="tradingview_dea54-wrapper"
                style={{
                  position: "relative",
                  boxSizing: "content-box",
                  width: "100%",
                  height: "95% !important",
                  margin: "0 auto !important",
                  padding: "0 !important",
                  fontFamily:
                    '-apple-system, BlinkMacSystemFont, "Trebuchet MS", Roboto, Ubuntu, sans-serif',
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "95%",
                    background: "transparent",
                    padding: "0 !important",
                  }}
                >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default FitScreen;
