import React, { useCallback, useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import LoginScreen from "./screens/LoginScreen";
import HomeScreen from "./screens/HomeScreen";
import FlipScreen from "./screens/FlipScreen";
import FitScreen from "./screens/FitScreen";
import FlyScreen from "./screens/FlyScreen";
import EcomAllyScreen from "./screens/EcomAllyScreen";
import AllCoursesScreen from "./screens/AllCoursesScreen";
import ChatScreen from "./screens/ChatScreen";
import SettingsScreen from "./screens/SettingsScreen";
import AdminScreen from "./screens/AdminScreen";
import LogoutScreen from "./screens/LogoutScreen";
import { AuthProvider } from './core/newauth';

import "./scss/styles.scss";

function App() {

    useEffect(() => {
      document.title = 'MOB';
    }, []);
  
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>

          <Route
            path="/" element={<LoginScreen />}
          />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/home" element={<HomeScreen />} />
          <Route path="/flip" element={<FlipScreen />} />
          <Route path="/fit" element={<FitScreen />} />
          <Route path="/fly" element={<FlyScreen />} />
          <Route path="/ecom-ally" element={<EcomAllyScreen />} />
          <Route path="/all-courses" element={<AllCoursesScreen />} />
          <Route path="/chat" element={<ChatScreen />} />
          <Route path="/settings" element={<SettingsScreen />} />
          <Route path="/admin" element={<AdminScreen />} />
          <Route path="/logout" element={<LogoutScreen />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
