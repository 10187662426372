import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../core/fb"; // import logout function from ../core/fb.js

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    async function handleLogout() {
      console.log("Calling logout function...");
      //await logout(); // call logout function from ../core/fb.js
      console.log("Clearing local storage...");
      localStorage.clear(); // clear local storage
      console.log("Navigating to login page...");
      navigate("/login");
    }

    handleLogout();
  }, [navigate]);

  return <div>Logging out...</div>;
}

export default Logout;
