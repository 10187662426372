import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MOBRLOGO from "../assets/images/MOB-Purple.png";
import { AuthContext } from "../core/newauth";
import "../scss/styles.scss";

function LoginScreen() {
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [triggerApiCall, setTriggerApiCall] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  //const [isAdmin, setIsAdmin] = useState(false);

  const navigate = useNavigate();

  // Get the states from AuthContext
  const { email, setEmail, hasSubscription, subscriptionStatus, isAdmin, setIsAdmin } = useContext(AuthContext);

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleLogin = async () => {
    if (!email || !password || !isValidEmail(email)) {
      setErrorMessage("Please enter a valid email and password.");
      return;
    }

    try {
      setLoading(true);
      const adminEmails = process.env.REACT_APP_ADMIN_EMAILS.split(',');

      // Bypass logic for admin
      if (adminEmails.includes(email)) {
        setIsLoggedIn(true);
        setIsAdmin(true);
        navigate("/home", { replace: true, state: { auth: true, isAdmin: true } });

      }
      if (email === process.env.REACT_APP_ADMIN5) {
          setIsLoggedIn(true);
          setIsAdmin(false);
          navigate("/home", { replace: true, state: { auth: true } });

        } else {
          setTriggerApiCall(true);

          // Normal login logic for non-admin users
          console.log(hasSubscription);
          console.log(subscriptionStatus);
          if (hasSubscription !== "no") {
            setIsLoggedIn(true);
            navigate("/home", { replace: true, state: { auth: true } });
          } else {
            setMessage("Your login is incorrect, or you do not have an active subscription");
          }
        }

    } catch (error) {
      setErrorMessage("An error occurred. Please try again later.");
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  const isValidEmail = (email) => {
    return !!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  return (
    <div className="login-screen">
      <img src={MOBRLOGO} width="300px" height="300px" alt="moblogo" />
      <h3 className="login-title2">Exclusive VIP Members Only.</h3>
      <input
        type="email"
        id="login-email"
        placeholder=""
        value={email}
        onChange={handleEmailChange}
      />
      <input
        type="password"
        id="login-password"
        placeholder=""
        value={password}
        onChange={handlePasswordChange}
      />
      <input
        type="button"
        className="login-button"
        value="Login"
        onClick={handleLogin}
      />
      Join the MOB?
      <div className="login-links">
        <a
          className="login-page-register-link"
          href={process.env.REACT_APP_FIT_50_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          FIT 50
        </a>

        <a
          className="login-page-register-link2"
          href={process.env.REACT_APP_FIT_AI_CHARTS_250_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;|&nbsp;FIT PRO
        </a>

        <a
          className="login-page-register-link3"
          href={process.env.REACT_APP_MOB_VIP_ELITE_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;|&nbsp;FIT ELITE
        </a>
      </div>

      {loading ? "Loading..." : ""}
      {message && <p>{message}</p>}
      {errorMessage && <p>{errorMessage}</p>}
    </div>
  );
}

export default LoginScreen;