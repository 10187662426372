import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Menu from "../components/MainMenu/Menu";
import "../scss/styles.scss";
import { AuthContext } from '../core/newauth';


function FlyScreen() {
  const navigate = useNavigate();
  // Inside HomeScreen component
  const { isLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/logout");
    }
  }, [isLoggedIn, navigate]);

  console.log("isLoggedIn:", isLoggedIn);
  
  return (
    <>
      <Menu />
      <div className="screen">
        <div className="fly-screen">
          <h2>"Financial Literacy (for) YOU"</h2>
          <p>
            An all-inclusive package that aims to help you
            <strong>"FLY"</strong> into your a better financial future
            leveraging the entire MOB platform of education and tools.
          </p>
          <br />
          <h3>
            <strong>"FLIP"</strong> leverage the powerful "Find (and) List Items
            Platform" which includes cutting edge tools and education for
            ecommerce.
          </h3>
          <Link to="/flip">
            <button>Access FLIP</button>
          </Link>
          <h3>
            Leverage powerful tools and systems to help you get financially{" "}
            <strong>"FIT"</strong>.
          </h3>
          <Link to="/fit">
            <button>Access FIT</button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default FlyScreen;
