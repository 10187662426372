import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MOBLOGO from "../../assets/images/MOB-Purple.png";
import { AuthContext } from "../../core/newauth";

function Menu() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isMBOpen, setMBOpen] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { auth } = state || {};
  const { isAdmin, setisAdmin } = useContext(AuthContext);

  const menuItems = [
    { name: "Home", route: "/home" },
    { name: "FIT", route: "/fit" },
    { name: "Think Tank", route: "/chat" },
    { name: "Idea Submission", route: "/admin" },
    { name: "Logout", route: "/logout" },
  ];
  console.log('Menu: '+isAdmin);
  const filteredMenuItems = isAdmin ? menuItems : menuItems.filter(item => item.route !== "/admin");

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    setMBOpen(!isMBOpen);
  };

  const handleMenuItemClick = async (route) => {
    console.log('Menu item clicked:', route);
    if (route === "/logout") {
      console.log('Logging out...');
      await navigate("/logout");
    } else if (isAdmin === true) {
      console.log('Navigating to:', route);
      //setisAdmin(true);
      navigate(route, { replace: true, state: { auth: true, isAdmin: true } });
      console.log('Menu Close');
      toggleMenu();
    } else {
      navigate(route, { replace: true, state: { auth: true } });
      console.log('Menu closed.');
      toggleMenu();
    }
  };

  return (
    <div className="theMenu">

      <img
        className="menu-logo"
        src={MOBLOGO}
        height="60px"
        width="60px"
        alt="moblogogold"
      />

      <div
        className={`menu-icon ${isMenuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#6B5082"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="3" y1="12" x2="21" y2="12" />
          <line x1="3" y1="6" x2="21" y2="6" />
          <line x1="3" y1="18" x2="21" y2="18" />
        </svg>
      </div>

      {/* Menu items */}
      <nav className={`menu ${isMenuOpen ? "open" : ""}`}>
        <div className={`menu-close ${isMenuOpen ? "open" : ""}`} onClick={toggleMenu}>CLOSE</div>

        <h3 className="menu-header">ON THE MENU...</h3>
        <ul>

          {filteredMenuItems.map((item) => (
            <div
              key={item.route}
              className="bar"
              onClick={() => handleMenuItemClick(item.route)}
            >
              {item.name}
            </div>
          ))}
        </ul>
      </nav>
    </div>
  );
}
export default Menu;